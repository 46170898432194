:root {
    --orange: #ff6347;
}

html {
    background-color: white;
    scroll-behavior: smooth;
}

body {
    margin: 0;
}